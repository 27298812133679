<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">互动问答</div>
      <div class="bt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="returnbt">
        <div class="lefttitle">{{ iteminfo.name }}</div>
        <div class="bt" @click="release">发布讨论</div>
      </div>
      <div class="bts">
        <div class="check-group">
          <el-radio-group v-model="queryParams.type">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">仅老师参与</el-radio>
            <el-radio :label="2">回复最多</el-radio>
          </el-radio-group>
          <el-select class="btleft" v-model="queryParams.belong_type" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input class="elinput" v-model="queryParams.keyword" placeholder="请输入内容" clearable>
            <i class="el-icon-search el-icon_search" slot="suffix"> </i>
          </el-input>
          <div class="bt restbt" @click="searchbt">查询</div>
          <div class="bt" @click="resetbt">重置</div>
        </div>
      </div>
      <div class="itemcontain">
        <div class="item" v-for="(item, index) in qadata" :key="index">
          <div class="title">
            {{ item.title }}
            <span slot="" v-if="item.is_top === 1" class="item-two-bt item-two-bt-active">
              置顶
            </span>
          </div>
          <div class="item-two">
            <div v-if="item.is_teacher_join === 1" class="item-two-bt">
              老师参与
            </div>
          </div>
          <div class="text">
            {{ item.content }}
          </div>
          <div class="bottom-contain">
            <div class="bottom-left">
              <el-image class="elimage" :src="item.avatar" />
              <div class="teach">{{ item.author_name }}</div>
              <div class="time">{{ item.created_at | dataformat}}</div>
              <div class="time">发布</div>
            </div>
            <div class="bottom-right">
              <div class="bottom-right-item">
                <el-image class="elimage1" :src="icon1" />
                <div class="num">{{ item.view_count }}</div>
              </div>
              <div class="line"></div>
              <div class="bottom-right-item" @click="setlikebt(item.id)">
                <template v-if="item.like_status == 0">
                  <el-image class="elimage2" :src="icon2" />
                  <div class="num">{{ item.like_count }}</div>
                </template>
                <template v-else-if="item.like_status == 1">
                  <el-image class="elimage2" :src="icon5" />
                  <div class="num">{{ item.like_count }}</div>
                </template>
              </div>
              <div class="line"></div>
              <div class="bottom-right-item" @click="toreply(item)">
                <el-image class="elimage3" :src="icon3" />
                <div class="num">{{ item.reply_count }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Customdialog ref="customdialog" title="发表评论">
      <div slot="dialogbody" class="dialogbody">
        <el-input class="elinput" v-model="title" placeholder="请输入标题" clearable></el-input>
        <Tinymce ref="editor" v-model="content" style="height: auto; border-radius: 22px" @setInput="getValue">
        </Tinymce>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="bt" @click="canclebt">取消</div>
        <div class="bt btactive" @click="submitbt">确定</div>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import { getQaList, setQaLike, getQaAdd } from "@/api/qa";
import dayjs from "dayjs";
import Tinymce from "@/components/VueTinymce/index.vue";
import Customdialog from "@/components/customdialog.vue";
export default {
  name: "mainpage",
  components: { Customdialog, Tinymce },
  data() {
    return {
      qa_id: "",
      title: "",
      content: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/查看.png"),
      icon2: require("@/assets/coursedetails/点赞.png"),
      icon3: require("@/assets/coursedetails/回复.png"),
      icon5: require("@/assets/coursedetails/dzxz.png"),
      queryParams: {
        keyword: "",
        belong_type: 0,
        type: 0,
        related_type: 1,
        related_id: "",
        course_sn: '',
        page: 1,
        per_page: 9999
      },
      options: [
        {
          value: 0,
          label: "全部讨论",
        },
        {
          value: 1,
          label: "我发表的问答",
        },
      ],
      qadata: [],
      iteminfo: {},
    };
  },
  filters: {
    dataformat: function (value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  computed: {},
  created() {
    console.log(this.$route.query);
    this.iteminfo = this.$route.query;
    this.qa_id = this.$route.query.course_sn;
    this.queryParams.course_sn = this.qa_id
    this.getqalist();
  },
  methods: {
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 1,
          componentId: "Mycourses",
        },
      });
    },
    release() {
      this.$refs.customdialog.dialogopenbt();
      this.content = "";
      this.title = "";
    },
    toreply(item) {
      // let obj = JSON.stringify(item);
      // let obj2 = JSON.stringify(this.iteminfo);
      this.$router.push({
        path: "/home/personalcenter/courseinteractiveqadetails",
        query: {
          id: item.id,
          // item: encodeURIComponent(obj),
          // iteminfo: encodeURIComponent(obj2),
        },
      });
    },
    releasebt() {
      this.$refs.customdialog.dialogopenbt();
    },
    canclebt() {
      this.$refs.customdialog.dialogclosebt();
    },
    searchbt() {
      this.getqalist();
    },
    resetbt() {
      this.queryParams.keyword = "";
      this.queryParams.belong_type = 0;
      this.queryParams.type = 0;
      this.getqalist();
    },
    setlikebt(id) {
      this.setQaLike({ id: id });
    },
    canclebt() {
      this.$refs.customdialog.dialogclosebt();
    },
    submitbt() {
      this.QaAdd({
        title: this.title,
        related_type: 1,
        related_id: "",
        course_sn: this.qa_id,
        content: this.content,
      });
    },
    QaAdd(params) {
      getQaAdd(params)
        .then((response) => {
          if (response.code == 0) {
            this.$refs.customdialog.dialogclosebt();
            this.getqalist();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getqalist() {
      this.qadata = [];
      getQaList(this.queryParams)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.qadata = response.data.data
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setQaLike(params) {
      setQaLike(params)
        .then((response) => {
          if (response.code == 0) {
            this.getqalist();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取富文本得值
    getValue(data) {
      this.content = data;
    },
  },
};
</script>
<style scoped lang="scss">










.mainpage {
  background: #ffffff;
  min-height: calc(100vh - 240px);
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
    height: 40px;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
    .bt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      margin-right: 20px;
      font-size: 14px;
    }
    .elinput {
      width: 300px;
      ::v-deep .el-input__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    background: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    .returnbt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lefttitle {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .bt {
        width: 64px;
          height: 36px;
          line-height: 36px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
        padding: 0 5px 0px 5px;
      }
    }
    .bts {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btleft {
        margin-left: 20px;
      }

      .check-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .check-all {
          margin-right: 20px;
        }
        .elinput {
          margin-left: 20px;
          width: 300px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .bt {
          margin-left: 10px;
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #3d84ff;
          border-radius: 2px;
          user-select: none;
          cursor: pointer;
          font-size: 14px;
        }
        .restbt {
          margin-right: 10px;
          background: #3d84ff;
          color: white;
        }
      }
    }
    .itemcontain {
      margin-top: 20px;
      background: #ffffff;
      .item {
        padding-top: 20px;
        padding-left: 30px;
        margin-bottom: 10px;
        height: 180px;
        border-bottom: 1px solid #ebeef5;
        .title {
          font-size: 20px;
          font-weight: 400;
          color: #222222;
          display: flex;
          .item-two-bt-active {
            border: 1px solid #ff4800;
            color: #ff4800;
            font-size: 12px;
            width: 40px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            margin-left: 10px;
            margin-top: 3px;
          }
        }
        .item-two {
          margin-top: 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .item-two-bt {
            margin-right: 10px;
            padding: 2px;
            background: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
        }
        .text {
          margin-top: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        .bottom-contain {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .bottom-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .elimage {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            .teach {
              margin-left: 10px;
              margin-right: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
            .time {
              margin-right: 10px;
              font-size: 12px;
              font-weight: 400;
              color: #a0a0a0;
            }
          }
          .bottom-right {
            padding-right: 100px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .bottom-right-item {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
              .elimage1 {
                width: 19px;
                height: 13px;
              }
              .elimage2 {
                width: 18px;
                height: 18px;
                margin-bottom: 4px;
              }
              .elimage3 {
                width: 19px;
                height: 16px;
              }
              .num {
                margin-left: 6px;
                font-size: 15px;
                font-weight: 400;
                color: #a0a0a0;
              }
            }
            .line {
              margin-left: 20px;
              margin-right: 20px;
              height: 20px;
              border-right: 1px solid #ebeef5;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  .releasebt {
    margin-top: 80px;
    margin-right: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bt {
      width: 64px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      user-select: none;
      cursor: pointer;
      color: white;
    }
  }

  .dialogbody {
    padding: 20px;
    .elinput {
      margin-bottom: 10px;
    }
  }
  .dialogfooter {
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bt {
      margin-right: 20px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #a0a0a0;
      user-select: none;
      cursor: pointer;
    }
    .btactive {
      background: #3d84ff;
      border: 1px solid #3d84ff;
      color: white;
    }
  }
}
</style>
